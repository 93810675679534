import axios from 'axios'
import { map } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Button, Card, Container, Grid, Header } from 'semantic-ui-react'
import Layout from '../components/Layouts/cakeDefault'
import useMediaQuery from '../hooks/useMediaQuery'
import styled from 'styled-components'
import NoItemCart from '../components/DatHangPage/NoItemInCart'

const StyledCardGroup = styled(Card.Group)`
  margin-top: 20px;
`

function KoreaCakePost() {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.FB !== 'undefined') {
      window.FB.XFBML.parse()
    }
  }, [])
  return (
    <div
      className="fb-post"
      data-href="https://www.facebook.com/savor.vn/posts/2911925975764909"
      data-width="350"
      data-show-text="true"
    >
      <blockquote
        cite="https://www.facebook.com/savor.vn/posts/2911925975764909"
        className="fb-xfbml-parse-ignore"
      >
        <p>
          🥳 SAVOR NHẬN ORDER BÁNH SINH NHẬT - BÁNH VẼ THEO PHONG CÁCH HÀN QUỐC
          🥳 Ngày trước, chẳng biết order bánh theo yêu cầu...
        </p>
        Posted by <a href="https://www.facebook.com/savor.vn/">Savor Cake</a>{' '}
        on&nbsp;
        <a href="https://www.facebook.com/savor.vn/posts/2911925975764909">
          Sunday, May 8, 2022
        </a>
      </blockquote>
    </div>
  )
}

function FeedbackAlbum() {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [data, setData] = useState([])
  useEffect(() => {
    axios
      .get('https://work.4-handy.com/api_savor/get-feedback-photos')
      .then((res) => {
        setData(res.data.slice(0, 8))
      })
      .catch((err) => console.log(err))
  }, [])
  return (
    <StyledCardGroup itemsPerRow={isDesktop ? 3 : 2}>
      {data &&
        map(data, (item) => {
          return (
            <Card
              image={item.source}
              as="a"
              key={item.source}
              href={`https://www.facebook.com/${item.id}`}
              target="_blank"
              rel="noopener noreferrer"
            />
          )
        })}
    </StyledCardGroup>
  )
}
export default function MenuCakePage() {
  return (
    <Layout
      title={'Savor Cake | Menu bánh sinh nhật'}
      description={
        'Menu bánh Savor Cake đa dạng mẫu mã, kích cỡ và hương vị, tươi ngon chất lượng, chỉ từ 120k'
      }
      endPoint={`menu-banh-sinh-nhat`}
    >
      <Container style={{ textAlign: 'center', marginTop: 20 }}>
        <Grid stackable columns={1} style={{ paddingTop: 50 }}>
          <Grid.Column>
            <Header as="h2">Menu bánh kem, bánh sinh nhật Savor Cake</Header>
            <NoItemCart
              content={
                'Vui lòng chuyển đến trang chủ để xem đầy đủ các mẫu bánh sinh nhật, bánh kem, mousse mới nhất'
              }
            />
          </Grid.Column>
        </Grid>

        <Grid stackable columns={2}>
          <Grid.Column>
            <Header as="h2">
              Bánh kem, bánh sinh nhật, bánh vẽ phong cách Hàn Quốc
            </Header>
            <KoreaCakePost></KoreaCakePost>
          </Grid.Column>
          <Grid.Column>
            <Header as="h2">Feedback khách hàng</Header>
            <FeedbackAlbum></FeedbackAlbum>
            <Button
              style={{ marginTop: 20, background: '#61A05C', color: '#fff' }}
              as="a"
              href="https://www.facebook.com/media/set/?set=a.2899763206981186"
              target="_blank"
              rel="noopener noreferrer"
            >
              Xem toàn bộ Album Feedback
            </Button>
          </Grid.Column>
        </Grid>
      </Container>
    </Layout>
  )
}
